import React from 'react';
import styled from 'styled-components'
import "./style.scss"

class DiscordWidget extends React.Component {

    render() {

        const Section = styled.section`
          margin: 0;
          top: auto;
          right: 20px;
          bottom: 20px;
          left: auto;
          position: fixed;
        `

        return (
            <Section id="DiscordWidget">
                <iframe class="discord" src="https://discord.com/widget?id=761760310708404235&theme=dark" width="350" height="500"
                        allowTransparency="true" frameBorder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </Section>
        )
    }
}

export default DiscordWidget